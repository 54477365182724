<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            Helios
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Exportación e Importación
        </b-card-title>
        <b-card-text class="mb-2">
          La informacion ingresada es confidencial.
        </b-card-text>

        <b-form 
          @submit.prevent
        >
          <b-row>
            <b-col cols="12">
              <b-form-group
                id="export"
                description="Ingrese precio de exportación."
                label="Exportación"
                label-for="export-input"
                :state="exportState"
                invalid-feedback="Ingresa un número"
              >
                <b-form-input
                  id="export-input"
                  @keypress="restrictNumber($event)"
                  :state="exportState"
                  v-model="exportPrice"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                id="import"
                description="Ingrese precio de importación."
                label="Importación"
                label-for="import-input"
                :state="importState"
                invalid-feedback="Ingresa un número"
              >
                <b-form-input
                  id="import-input"
                  @keypress="restrictNumber($event)"
                  :state="importState"
                  v-model="importPrice"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-button
              variant="primary"
              type="submit"
              block
              @click="registerData()"
            >
              Guardar
            </b-button>
          </b-row>
          <hr>
        </b-form>
      </b-card>
    </div>
    <!-- MODAL ALERTA -->
    <b-modal
      id="alertModal"
      title="Alerta"
      ok-title="Voy a revisarlo"
      ok-only
      centered
      no-close-on-backdrop
      hide-header-close
    >
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Faltan datos
        </h4>
        <div class="alert-body font-medium-2">
          <p>Favor de llenar todos los campos.</p>
        </div>
      </b-alert>
    </b-modal>
  </div>
</template>


<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>


<script>
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BRow, BCol, BAlert, BCardBody,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'


export default {
  components: {
      BButton,
      BForm,
      BFormInput,
      BFormGroup,
      BCard,
      BLink,
      BCardTitle,
      BCardText,
      BCardBody,
      BInputGroup,
      BRow,
      BCol,
      BAlert,
      VuexyLogo
  },
  data() {
    return {
      // Data
      exportPrice: null,
      importPrice: null,

      // Props
      exportState: null,
      importState: null
    }
  },
  methods: {
    registerData() {

      // Faltan datos
      if (!(this.exportPrice) || !(this.importPrice)) {
        !(this.exportPrice) ? this.exportState = false : this.exportState = null;
        !(this.importPrice) ? this.importState = false : this.importState = null;
        this.$bvModal.show('alertModal');
        return;
      }
    },
    restrictNumber(event) {  // Sólo números pueden ser ingresados
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      let stringValue = event.target.value.toString();
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || stringValue.indexOf('.') != -1)) {  // 46 is dot
        event.preventDefault();
      }
    },
  }
}
</script>
